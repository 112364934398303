





















































































































































































































































// Required imports
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";

// Components
import recommendedStreams from "@/components/RecommendedStreams.vue";
import VideoComponent from "@/components/shared/VideoComponent.vue";
import Ads from "@/components/shared/Ads.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import SessionSwimLane from "@/components/sessions/SessionSwimLane.vue";
import featuredSection from "@/components/home/section/featuredSection.vue";
import TransparentSponsorTemplate from "@/components/home/section/TransparentSponsorTemplate.vue";
import SingleSponsorDisplay from "@/components/home/section/SingleSponsorDisplay.vue";
import TradeshowSwimlaneWithDifferentLogoSizes from "@/components/home/section/TradeshowSwimlaneWithDifferentLogoSizes.vue";
import FeaturedExhibitorSwimlane from "@/components/home/section/FeaturedExhibitorSwimlane.vue";
import StaticPageIcons from "@/components/home/section/StaticPageIcons.vue";
import StaticPage from "@/components/home/section/StaticPage.vue";
import StaticPartnerPage from "@/components/home/staticSection/StaticPartnerPage.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import AdSlot from "@/components/shared/AdSlot.vue";
import TradeshowSwimlaneWithHeaderTier from "@/components/tradeshow/TradeshowSwimlaneWithHeaderTier.vue";
import SvgNetworking from "@/components/svg/svg-networking.vue";

// Vuex modules
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
import featuredVideoVuexModule from "@/store/vuex-modules/getFeaturedVideo";
import featuredAttendeeVuexModule from "@/store/vuex-modules/getFeaturedAttendees";
import featuredContentVuexModule from "@/store/vuex-modules/featuredContent";

// Types
import { SessionDataArray } from "@/types/interfaces";

// Define Vuex module variables
const layoutImagesStore = getModule(layoutImagesVuexModule);
const sessionStore = getModule(sessionVuexModule);
const featuredVideoStore = getModule(featuredVideoVuexModule);
const featuredContentStore = getModule(featuredContentVuexModule);
const featuredAttendeeStore = getModule(featuredAttendeeVuexModule);

@Component({
    components: {
        recommendedStreams,
        VideoComponent,
        "featured-attendee": () => {
            let returnResult = store.getters.getPageOptions("home").section
                ?.attendeeSwimlane?.componentToLoad;
            if (!returnResult) {
                returnResult = "AttendeesTaxonomySwimlane.vue";
            }

            return import(`@/components/attendees/swimlanes/${returnResult}`);
        },
        Ads,
        SessionSwimLane,
        Spinners,
        featuredSection,
        TransparentSponsorTemplate,
        SingleSponsorDisplay,
        FeaturedExhibitorSwimlane,
        TradeshowSwimlaneWithDifferentLogoSizes,
        StaticPageIcons,
        StaticPage,
        StaticPartnerPage,
        AttendeeCard,
        AdSlot,
        TradeshowSwimlaneWithHeaderTier,
        SvgNetworking
    }
})
export default class Home extends Vue {
    posterImage = "";
    prev = "";
    session = "";
    bg = "";
    color = "";
    live = "";
    // chatter = "";

    videoBackgroundImage = "";
    isLoadingVideo = true;
    isLoadingSessions = true;
    isLoadingFeaturedContent = true;
    recommendedStreamsSection = "";
    featuredSection = "";

    isFeaturedAttendeeDefaultTemplate = false;

    /**
     * Getters / computed 123 - 3456
     */
    get notProduction() {
        return Boolean("production" !== process.env.NODE_ENV);
    }

    get sectionToUse() {
        const res = this.$store.getters.getPageOptions("home").section;
        const recommendedRes = res.recommendedStreams;
        const bgResult = res.videoContainer.bgDecor;

        this.recommendedStreamsSection =
            recommendedRes.componentToLoad == ""
                ? "recommendedStreams"
                : recommendedRes.componentToLoad;

        this.featuredSection = res.featuredSolutionProvider.componentToLoad
            ? res.featuredSolutionProvider.componentToLoad
            : "featuredSection";

        this.videoBackgroundImage = bgResult
            ? `${process.env.BASE_URL}bg/${bgResult}`
            : "";

        this.isFeaturedAttendeeDefaultTemplate =
            res.attendeeSwimlane.componentToLoad || false;

        return res;
    }

    get siteClient() {
        return this.getLayoutOptions.siteName;
    }

    get bannerOption(): boolean {
        if (this.$store.getters.getPageOptions("banner")) {
            const bannerOption = this.$store.getters.getPageOptions("banner");
            return bannerOption?.bannerAds?.isActive ? true : false;
        } else {
            return false;
        }
    }

    get recommendedSectionBg() {
        return layoutImagesStore.getLayoutImage("recommendedSection");
    }

    get featuredAttendeeSectionBg(): string {
        return layoutImagesStore.getLayoutImage("featuredAttendeeHome");
    }

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }

    get homeLabel() {
        return this.$store.getters.homeLabel;
    }

    get getLayoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    get sessionData(): SessionDataArray {
        return sessionStore.sessionPromos ? sessionStore.sessionPromos : [];
    }

    get featuredVideo() {
        return featuredVideoStore.featuredVideo;
    }

    get featuredContent() {
        return featuredContentStore.featuredContent;
    }

    get myOwnProfileId() {
        return this.$store.getters.user.id;
    }

    get attendeeSwimlane() {
        return featuredAttendeeStore.featuredAttendeeSwimlanes || [];
    }

    get featuredAttendeesLoaded() {
        return featuredAttendeeStore.featuredAttendeesLoaded;
    }

    get pageOptions() {
        return this.$store.getters.getPageOptions("attendees");
    }
    get attendeeSwimlanes() {
        return this.pageOptions.swimLanes ? this.pageOptions.swimLanes : [];
    }

    get attendeeTier() {
        const att = this.attendeeSwimlane.flatMap((item) => {
            return item.data;
        });

        return att.filter((item, index) => index !== 0);
    }

    get siteName() {
        return this.$store.state.themeConfig.conferenceName;
    }

    /**
     * Lifecycle
     */

    created() {
        this.init();
        this.loadTwitterEmbed();
        featuredAttendeeStore.getFeaturedAttendees(this.attendeeSwimlanes);
    }

    /**
     * Methods
     */

    init() {
        const promises = [
            featuredVideoStore.getFeaturedVideo(),
            sessionStore.getSessionData({
                tableName: this.$store.getters.awsConfig.sessionTable,
                isDemoData: false
            }),
            featuredContentStore.getFeaturedContent()
        ];
        Promise.allSettled(promises).then(() => {
            this.isLoadingVideo = false;
            this.isLoadingSessions = false;
            this.isLoadingFeaturedContent = false;
        });

        if (
            this.sectionToUse.attendeeSwimlane?.useSection &&
            Array.isArray(this.sectionToUse.attendeeSwimlane?.swimlanes) &&
            this.sectionToUse.attendeeSwimlane?.swimlanes.length
        ) {
            featuredAttendeeStore.getFeaturedAttendees(
                this.sectionToUse.attendeeSwimlane.swimlanes
            );
        }
    }

    bgColor(section: string) {
        if (section == "previous") {
            return this.cssVariables["--home-prev-session-bg-color"] != ""
                ? "bg-prev-session"
                : "bg-gradient";
        } else if (section == "featured-section") {
            return this.cssVariables["--home-solution-provider-bg-color"] != ""
                ? "bg-featured-solution"
                : "bg-gradient";
        } else {
            return null;
        }
    }

    loadTwitterEmbed() {
        window.twttr.widgets.load(
            document.getElementById("twitter-timeline--embed")
        );
    }

    backgroundImageIfPresent(imageVar: string) {
        if (imageVar) {
            return {
                "background-image": `url(${imageVar})`
            };
        } else {
            return {};
        }
    }
}
