var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.isLoading)?_c('Spinners',{staticClass:"py-8"}):(_vm.hasLobbyData)?_c('section',{staticClass:"hidden lg:block relative w-full"},[_c('LobbyContent',{directives:[{name:"addSvg",rawName:"v-addSvg"}],staticClass:"w-full h-auto -mt-12 -mb-8"}),(_vm.loadVideo)?_c('div',{staticClass:"bg-primary w-2/5 video-Player"},[(_vm.isLoadingVideo)?_c('Spinners',{staticClass:"my-16"}):(_vm.featuredVideo && _vm.featuredVideo.videoUrl)?_c('div',{key:"db-video",staticClass:"container"},[_c('video-component',{attrs:{"sessionData":{
                        sessionId: null,
                        title: _vm.featuredVideo.title
                    },"options":{
                        autoplay: false,
                        controls: true,
                        sources: [
                            {
                                src: _vm.featuredVideo.videoUrl,
                                type: 'application/x-mpegURL'
                            }
                        ],
                        poster: _vm.featuredVideo.thumbnail
                    }}})],1):(_vm.notProduction)?_c('div',{key:"default-video",staticClass:"container block m-auto"},[_c('div',{staticClass:"videoWrapper"},[_c('iframe',{staticClass:"video",attrs:{"src":"https://player.vimeo.com/video/403106834","width":"640","height":"564","title":"Introduction to Virtual Meeting Platform","frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":"","muted":""}})])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"featured-section  overflow-hidden p-4"},[_c('h3',{staticClass:"text-sm xl:text-xl tetx-featured-content px-2"},[_vm._v(" "+_vm._s(_vm.homeLabel.previousSessionHeader)+" ")]),(_vm.isFeaturedDataLoading)?_c('Spinners',{staticClass:"py-8"}):_c('VerticalCarousel',{staticClass:"swiper-container--custom mt-16 2xl:mt-8 pb-8 3xl:pb-4",attrs:{"featuredData":_vm.featuredSession}})],1),(
                _vm.pageOptions.section.featuredSolutionProvider.useSection &&
                    _vm.isLoaded
            )?_c('div',{staticClass:"home-sponsor md:p-4"},[_c('h3',{staticClass:"text-sm xl:text-lg font-bold pb-4"},[_vm._v(" In Partnership with ")]),_c('img',{staticClass:"w-full",attrs:{"src":_vm.logoImage,"alt":"Partnership"}})]):_vm._e()],1):_c('NotFound'),_c('AdSlot',{attrs:{"slot-name":"home"}}),(_vm.pageOptions.section.staticPage.active)?_c('div',{staticClass:"hidden lg:block"},[_c('StaticPage',{attrs:{"staticPages":_vm.pageOptions.section.staticPage.pages}})],1):_vm._e(),(_vm.pageOptions.section.attendeeSwimlane.useSection)?_c('section',{staticClass:"hidden lg:block bg-attendee-taxonomy-container py-4"},[(_vm.isAttendeeLoading)?_c('Spinners',{staticClass:"py-8"}):_vm._l((_vm.attendeeSwimlane),function(swimlane){return _c('AttendeeSwimlane',{key:swimlane.key,staticClass:"container py-8",attrs:{"header-label":swimlane.headerLabel,"header-centered":swimlane.headerCentered,"attendees":swimlane.data}})})],2):_vm._e(),_c('Home',{staticClass:"lg:hidden"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }