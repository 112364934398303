<template>
    <div>
        <swiper class="swiper h-full" :options="swiperOption">
            <swiper-slide v-for="data in featuredData" :key="data.itemId">
                <a
                    v-if="isValidURL(data.url)"
                    :href="data.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><img :src="data.image" :alt="data.title"
                /></a>
                <router-link v-else :to="data.url"
                    ><img :src="data.image" :alt="data.title"
                /></router-link>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="swiper-button-prev text-white"></div>
        <div class="swiper-button-next text-white"></div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css/swiper.css";

export default {
    name: "VerticalCarousel",
    title: "Vertical slider",
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        featuredData: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            swiperOption: {
                direction: "vertical",
                slidesPerView: 3,
                spaceBetween: 20,
                mousewheel: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            }
        };
    },
    updated() {
        Array.from(document.getElementsByClassName("swiper-slide")).forEach(
            (item) => {
                item.style.removeProperty("height");
            }
        );
    },
    methods: {
        isValidURL(url) {
            const chckUrl = url.match(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
            );
            return chckUrl !== null;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../styles/components/verticalcarousel";

.swiper-button-next,
.swiper-button-prev {
    left: 50%;
    transform: rotate(90deg);
    transform-origin: left center;
}

.swiper-button-prev {
    top: 60px;
}

.swiper-button-next {
    top: auto;
    bottom: 10px;
}
</style>
