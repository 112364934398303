









































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Spinners from "@/components/utilities/Spinners.vue";
import NotFound from "@/views/NotFound.vue";
import eventHub from "@/event-hub";
import store from "@/store";

import VerticalCarousel from "@/components/shared/VerticalCarousel.vue";
import VideoComponent from "@/components/shared/VideoComponent.vue";
import Home from "@/views/Home.vue";
import AdSlot from "@/components/shared/AdSlot.vue";
import StaticPage from "@/components/home/section/StaticPage.vue";

import featuredContentVuexModule from "@/store/vuex-modules/featuredContent";
import featuredVideoVuexModule from "@/store/vuex-modules/getFeaturedVideo";
import featuredAttendeeVuexModule from "@/store/vuex-modules/getFeaturedAttendees";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";

import { SessionDataArray } from "@/types/interfaces";

const featuredContentStore = getModule(featuredContentVuexModule);
const featuredVideoStore = getModule(featuredVideoVuexModule);
const featuredAttendeeStore = getModule(featuredAttendeeVuexModule);
const sessionStore = getModule(sessionVuexModule);

@Component({
    components: {
        Home,
        Spinners,
        VideoComponent,
        VerticalCarousel,
        AdSlot,
        StaticPage,
        NotFound,
        LobbyContent: () => {
            const options = store.getters.getPageOptions("home")
                .componentVariance;

            let componentToUse = "";

            options.forEach((item: any) => {
                if (item.isActive) {
                    componentToUse = item.component;
                }
            });

            return import(`./svg/${componentToUse}`);
        },
        AttendeeSwimlane: () => {
            const returnResult = store.getters.getPageOptions("home").section
                ?.attendeeSwimlane?.componentToLoad;
            return import(`@/components/attendees/swimlanes/${returnResult}`);
        }
    },
    directives: {
        addSvg: {
            inserted() {
                eventHub.$emit("HomeLobbySvgInserted");
            }
        }
    }
})
export default class LobbyHomePage extends Vue {
    isLoading = true;
    isLoadingVideo = true;
    hasLobbyData = false;
    isAttendeeLoading = true;
    loadVideo = false;
    loadFetauredContent = false;
    featuredSession: any = [];
    publicPath = process.env.BASE_URL;
    isFeaturedDataLoading = true;
    isLoaded = false;

    /**
     * Computed
     */
    get notProduction() {
        return Boolean("production" !== process.env.NODE_ENV);
    }

    get pageOptions() {
        return this.$store.getters.getPageOptions("home") || {};
    }

    get featuredContent() {
        return featuredContentStore.featuredContent;
    }

    get sessionData(): SessionDataArray {
        return sessionStore.sessionPromos ? sessionStore.sessionPromos : [];
    }

    get featuredVideo() {
        return featuredVideoStore.featuredVideo;
    }
    get attendeeSwimlane() {
        return featuredAttendeeStore.featuredAttendeeSwimlanes || [];
    }
    get attendeeSwimlanes() {
        return this.attendeePageOptions.swimLanes
            ? this.attendeePageOptions.swimLanes
            : [];
    }

    get attendeePageOptions() {
        return this.$store.getters.getPageOptions("attendees");
    }

    get homeLabel() {
        return this.$store.getters.homeLabel;
    }

    get logoImage() {
        return this.publicPath + "logos/partnership.svg";
    }

    /**
     * Lifecycle
     */
    created() {
        eventHub.$on("HomeLobbySvgInserted", this.handleRoomItemsInit);
        this.hasLobbyData = true;
        this.isLoading = false;
        const newPromises = [
            featuredContentStore.getFeaturedContent(),
            featuredVideoStore.getFeaturedVideo(),
            sessionStore.getSessionData({
                tableName: this.$store.getters.awsConfig.sessionTable,
                isDemoData: false
            })
        ];

        Promise.allSettled(newPromises)
            .then(() => {
                this.featuredSession = [...this.featuredContent];
                this.isLoadingVideo = false;
            })
            .finally(() => {
                this.loadFetauredContent = true;
                this.isFeaturedDataLoading = false;
            });

        featuredAttendeeStore
            .getFeaturedAttendees(this.attendeeSwimlanes)
            .finally(() => (this.isAttendeeLoading = false));
    }

    beforeDestroy() {
        eventHub.$off("HomeLobbySvgInserted", this.handleRoomItemsInit);
    }

    /**
     * Methods
     */
    checkBtn(btnID: string): boolean {
        return this.pageOptions.section.svgButtons.find((id: any) => {
            if (id.id === btnID) {
                return id.useButton;
            }
        });
    }
    handleRoomItemsInit() {
        const elementWithLink = document.querySelectorAll(".with-link");

        [...elementWithLink].forEach((item: any) => {
            item.classList.add("cursor-pointer");

            switch (item.id) {
                case "help-page":
                    item.addEventListener("click", () => {
                        this.$router.push("/help");
                    });
                    break;
                case "attendeebtn1":
                    if (this.checkBtn("attendeebtn1")) {
                        item.classList.remove("hidden");
                    }

                    item.addEventListener("click", () => {
                        this.$router.push("/attendees");
                    });
                    break;
                case "Guestbook":
                    if (this.checkBtn("Guestbook")) {
                        item.classList.remove("hidden");
                    }
                    item.addEventListener("click", () => {
                        window.open(
                            "https://fact.kudoboard.com/boards/jn776iLS/25thanniversary",
                            "_blank"
                        );
                        const opener: any = window.open();
                        opener.opener = null;
                    });
                    break;
                case "VC-tip":
                    if (this.checkBtn("VC-tip")) {
                        item.classList.remove("hidden");
                    }
                    item.addEventListener("click", () => {
                        this.$router.push("/sessions/on-demand-04");
                    });
                    break;
                case "messageCenter":
                    item.addEventListener("click", () => {
                        this.$router.push("/message-center");
                    });
                    break;
                case "schedule":
                    if (this.checkBtn("schedule")) {
                        item.classList.remove("hidden");
                    }
                    item.addEventListener("click", () => {
                        this.$router.push("/schedule");
                    });
                    break;
                case "attendeebtn2":
                    if (this.checkBtn("attendeebtn2")) {
                        item.classList.remove("hidden");
                    }

                    item.addEventListener("click", () => {
                        this.$router.push("/attendees");
                    });
                    break;
            }
        });

        this.loadVideo = true;
        this.isLoaded = true;
    }
}
